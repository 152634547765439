/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Palette
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
@import '@ionic/angular/css/palettes/dark.class.css';
// @import '@ionic/angular/css/palettes/dark.system.css';

// Disable Safari Live Text
img {
  -webkit-user-select: none;
}

a {
  cursor: pointer;
}

ion-item {
  --transition: none;
}

ion-content.limited-width {
  --padding-bottom: 100px;
}

ion-list ion-label strong {
  display: block;
}

ion-list ion-label ion-note {
  font-size: 0.9em;
}

ion-item ion-note {
  color: var(--ion-color-medium);
}

.limited-width[color=light]::part(background) {
  background-color: var(--ion-color-light);
}

.limited-width::part(scroll) {
  display: grid;
  grid-template-columns: [full-start] 1fr [main-start] min(700px, 100% - 32px) [main-end] 1fr [full-end];
  grid-auto-rows: max-content;
  // gap: 16px;
  // padding-inline: 16px;
  // padding-inline: max(var(--padding-start), (100% - 700px) / 2);
}

.limited-width > * {
  grid-column: main;
}

@media (max-width: 716px) {
  #cw-widget-holder {
    top: calc(var(--ion-safe-area-top) + (100dvh - var(--vh)));
    height: calc(var(--vh) - var(--ion-safe-area-top));
  }
  .limited-width > * {
    grid-column: full;
  }
}

.limited-width > .full-width {
  grid-column: full;
}

.list-note {
  display: block;
  margin-inline: 32px;
  font-size: 0.8em;
  margin-top: -8px;
}

.list-note-section {
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: -8px;
}

.list-note + .list-note {
  margin-top: 16px;
}

.list-ios.list-inset:has(+ ion-list-header) {
  margin-bottom: 0;
}

ion-list-header + .list-ios.list-inset {
  margin-top: 0;
}

.site-card {
  > ion-badge {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }

  ion-card-header {
    position: relative;
    overflow: hidden;
    background-image: url(./assets/default-publication-cover.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    aspect-ratio: 3;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 40%, rgba(0,0,0,.65) 100%);
    }
  }

  ion-card-title, ion-card-subtitle {
    color: #ffffff;
  }

  ion-card-content {
    padding: 0;
  }

}

.callout {
  display: grid;
  grid-template-columns: [content-start] 1fr [content-end];
  gap: 16px;
  padding: 16px;
  border: solid 1px;
  border-radius: 8px;

  * {
    padding: 0;
    margin: 0;
    grid-column: content;
  }

  ion-icon {
    grid-column: icon;
    align-self: start;
  }

  a {
    color: inherit;
  }

  &.info {
    background-color: var(--ion-color-info);
    color: var(--ion-color-info-contrast);
  }
  &.warning {
    background-color: var(--ion-color-warning-tint);
    border-color: var(--ion-color-warning-shade);
    color: var(--ion-color-warning-contrast);
  }
  &.danger {
    background-color: var(--ion-color-danger-tint);
    border-color: var(--ion-color-danger-shade);
    color: var(--ion-color-danger-contrast);
  }
}

.callout:has(> ion-icon) {
  grid-template-columns: [icon-start] min-content [icon-end content-start] 1fr [content-end];
}

.auth-view {
  .logo-container {
    height: 50px;
    border-radius: 10px;
    margin: 32px 16px;
    background-image: url('./assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent
  }

  hr {
    --bg-color: var(--ion-background-color);
    --line-color: var(--ion-text-color);
    color: var(--line-color);
    overflow: visible;
    text-align: center;
    height: 24px;
    margin-inline: 24px;
    position: relative;
  }

  hr::after {
    content: 'or';
    background-color: var(--bg-color);
    padding: 0 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  form>button {
    visibility: hidden;
    position: absolute;
    position: -999px;
  }
}

.ion-palette-dark .logo-container {
  background-image: url('./assets/logo-dark.png');
}
